<template>
  <div class="money_box margin-top-sm">
    <div class="money_arr clr">
      <div class="money_sum fl">
        <div class="box">
          <div class="title">可用总余额</div>
          <div class="money_num">{{ account.list | currency }}</div>
          <div class="money_btn tac">
            <router-link to="add_money">
              <el-button
                type="primary"
                @click="MtaH5.clickStat('money', { add: 'true' })"
                >立即充值</el-button
              >
            </router-link>
          </div>
        </div>
      </div>
      <div class="money_count">
        <div class="count_box">
          <el-row>
            <el-col :span="1">
              <div class="sign">=</div>
            </el-col>
            <el-col :span="3">
              <div class="pay">
                <div class="title">
                  <span class="text">充值余额</span>
                </div>
                <div class="money">
                  {{ account.rechargeBalance | currency }}
                </div>
              </div>
            </el-col>
            <el-col :span="1">
              <div class="sign">+</div>
            </el-col>
            <el-col :span="3">
              <div class="pay">
                <div class="title">赠送余额</div>
                <div class="money">{{ account.presentBalance | currency }}</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="money_table">
      <div class="table_tabs"></div>
      <div class="table_box">
        <table-pay ref="table_pay"></table-pay>
      </div>
    </div>
  </div>
</template>

<script>
import TablePay from "@/components/money/table_pay";
export default {
  components: {
    TablePay,
  },

  data() {
    return {
      account: "",
      action: 1,
    };
  },
  mounted() {
    this.money_list();
  },
  methods: {
    // 账户信息
    money_list() {
      this.$api.account().then((res) => {
        this.account = {
          list: res.data.balance,
          add: res.data.cumulativePayment,
          send: res.data.cumulativePresent,
          bill: res.data.invoiceAmount,
          quit: res.data.cumulativeRefund,
          use: res.data.cumulativeConsumption,
          presentBalance: res.data.presentBalance,
          rechargeBalance: res.data.rechargeBalance,
        };
      });
    },
    pay(e) {
      this.action = e;
      this.$refs.table_pay.get_table(e);
    },
  },
};
</script>

<style lang="scss" scoped>
$yellow: rgb(242, 200, 17);
.money_box {
  .money_arr {
    width: 100%;
    height: 248px;
    background: #ffffff;
    .money_sum {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      height: 246px;
      width: 293px;
      border: 1px solid $yellow;
      /* 水平居中*/
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-pack: center; /* IE 10 */
      -webkit-justify-content: center;
      -moz-justify-content: center;
      justify-content: center; /* IE 11+,Firefox 22+,Chrome 29+,Opera 12.1*/
      /* 垂直居中 */
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-align: center; /* IE 10 */
      -webkit-align-items: center;
      -moz-align-items: center;
      .box {
        width: 100%;
      }
      .title {
        font-size: 16px;
        text-align: center;
      }
      .money_num {
        color: rgb(255, 0, 0);
        font-weight: bold;
        text-align: center;
        line-height: 60px;
        font-size: 22px;
      }
      .money_btn {
        margin: auto;
        margin-top: 10px;
        button {
          font-weight: bold;
        }
      }
    }

    .money_count {
      margin-left: 295px;
      @extend .money_sum;
      height: inherit;
      width: auto;
      border: inherit;
      .send_money {
        p {
          color: rgb(255, 0, 0);
          font-weight: bold;
        }
        i {
          display: block;
          padding: 0;
          font-size: 18px;
          line-height: 1.2;
        }
      }
      .count_box {
        width: 100%;
      }
      .sign {
        font-size: 40px;
        text-align: center;
        vertical-align: top;
        line-height: 78px;
      }
      .pay {
        font-size: 16px;
        line-height: 33px;
        text-align: center;
        .title {
          text-align: center;
        }
        .money {
          font-weight: bold;
          line-height: 45px;
        }
        .pay_btn {
          line-height: 0;
          margin-bottom: 8px;
          button {
            font-weight: bold;
          }
          .el-button--warning {
            padding: 9px 31px;
          }
        }
      }
    }
  }
  .money_table {
    .table_tabs {
      margin-top: 15px;
      margin-bottom: 15px;
      .el-button {
        color: rgb(0, 0, 0);
      }
    }
  }
}
</style>
