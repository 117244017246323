<template>
  <div class="table_pay">
    <div class="table_pay_state">
      <el-tabs v-model="option_value">
        <el-tab-pane label="订单消费" name="0"></el-tab-pane>
        <el-tab-pane label="账户充值" name="1"></el-tab-pane>
        <el-tab-pane label="订单返款" name="2"></el-tab-pane>
        <el-tab-pane label="余额提现" name="3"></el-tab-pane>
      </el-tabs>
    </div>
    <div v-show="option_value === '0'">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="trade_no" label="订单号"></el-table-column>
        <el-table-column
          prop="orderAmount"
          label="订单金额"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="cashAmount"
          label="现金消费"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="rechargeBalanceAmount"
          label="充值余额消费"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="presentBalanceAmount"
          label="赠送余额消费"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="rechargeBalance"
          label="充值余额"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="presentBalance"
          label="赠送余额"
          width="90"
        ></el-table-column>
        <!-- <el-table-column prop="invoice_status" label="开票情况" width="100"> -->
        <el-table-column label="开票情况" width="100">
          <template slot-scope="scope">
            <router-link
              to="bill?id=2"
              v-if="scope.row.invoice_status === '未开票'"
            >
              <a class="text-blue">
                {{ scope.row.invoice_status }}
              </a>
            </router-link>
            <span v-else>{{ scope.row.invoice_status }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceNo" label="发票号"></el-table-column>
        <el-table-column
          prop="createAt"
          label="时间"
          width="170"
        ></el-table-column>
      </el-table>
    </div>
    <div v-show="option_value === '1'">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="trade_no" label="充值流水号"></el-table-column>
        <el-table-column
          prop="rechargeAmount"
          label="充值"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="presentAmount"
          label="赠送"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="rechargeBalance"
          label="充值余额"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="presentBalance"
          label="赠送余额"
          width="90"
        ></el-table-column>
        <el-table-column prop="invoice_status" label="开票情况" width="100">
          <template slot-scope="scope">
            <router-link
              to="bill?id=2"
              v-if="scope.row.invoice_status === '未开票'"
            >
              <a class="text-blue">
                {{ scope.row.invoice_status }}
              </a>
            </router-link>
            <span v-else>{{ scope.row.invoice_status }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceNo" label="发票号"></el-table-column>
        <el-table-column
          prop="createAt"
          label="时间"
          width="170"
        ></el-table-column>
      </el-table>
    </div>
    <div v-show="option_value === '2'">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="trade_no" label="订单号"></el-table-column>
        <el-table-column
          prop="rechargeAmount"
          label="充值金额"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="presentBalance"
          label="赠送余额"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="balanceAmount"
          label="返款金额"
          width="90"
        ></el-table-column>
        <el-table-column
          prop="presentBalanceAmount"
          label="赠送账户返款"
        ></el-table-column>
        <el-table-column
          prop="rechargeBalanceAmount"
          label="充值账户返款"
        ></el-table-column>
        <el-table-column
          prop="createAt"
          label="时间"
          width="170"
        ></el-table-column>
      </el-table>
    </div>
    <div v-show="option_value === '3'">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="trade_no" label="提现流水号"></el-table-column>
        <el-table-column
          prop="balanceAmount"
          label="提现金额"
        ></el-table-column>
        <el-table-column
          prop="rechargeBalance"
          label="充值账户余额"
        ></el-table-column>
        <el-table-column
          prop="createAt"
          label="时间"
          width="170"
        ></el-table-column>
      </el-table>
    </div>
    <div class="paging_arr clr">
      <div class="btn_right fr">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[10, 25, 50, 100]"
          layout="sizes, prev, pager, next"
          :total="page_num"
        ></el-pagination>
      </div>
      <div class="hint_left fr">共找到{{ page_num }}条内容</div>
    </div>
  </div>
</template>
<script>
import { moment_set } from "@/assets/js/validate";
import moment from "moment";
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      page_num: 0,
      options: [
        {
          label: "订单消费",
          value: 0,
        },
        {
          label: "充值订单",
          value: 1,
        },
        {
          label: "订单返款",
          value: 2,
        },
        {
          label: "余额提现",
          value: 3,
        },
      ],
      option_value: "",
      info: {
        pageIndex: 1,
        pageSize: 10,
        type: "",
      },
    };
  },
  watch: {
    option_value(e) {
      this.info.type = parseInt(e);
      this.get_table();
      switch (e) {
        case "0":
          this.MtaH5.clickStat("money", { dd: "true" });
          break;
        case "1":
          this.MtaH5.clickStat("money", { cz: "true" });
          break;
        case "2":
          this.MtaH5.clickStat("money", { fx: "true" });

          break;
        case "3":
          this.MtaH5.clickStat("money", { tx: "true" });

          break;
      }
    },
    currentPage(e) {
      if (e === 1) {
        this.info.pageIndex = 1;
      }
    },
  },
  created() {
    this.option_value = "0";
  },
  methods: {
    handleSizeChange(e) {
      // 页
      this.info.pageSize = e;
      this.get_table();
    },
    handleCurrentChange(e) {
      this.info.pageIndex = e;
      this.get_table();
    },
    get_table(e) {
      this.$api.logs(this.info).then((res) => {
        if (res.errcode === 0) {
          let array = [];
          res.data.list.forEach((e, i) => {
            array.push({
              trade_no: e.trade_no,
              rechargeAmount: e.rechargeAmount,
              presentAmount: e.presentAmount,
              cashAmount: e.cashAmount,
              balanceAmount: e.balanceAmount,
              balance: e.balance,
              invoice_status: this.bill_num(e.invoice_status),
              invoiceCode: e.invoiceCode || "-",
              invoiceNo: e.invoiceNo || "-",
              createAt: moment_set(e.createAt) || "-",
              presentBalanceAmount: e.presentBalanceAmount,
              rechargeBalance: e.rechargeBalance,
              rechargeBalanceAmount: e.rechargeBalanceAmount,
              presentBalance: e.presentBalance,
              orderAmount: e.orderAmount,
            });
          });
          this.tableData = array;
          this.page_num = res.data.totalCount;
        }
      });
    },
    bill_num(e) {
      switch (e) {
        case 0:
          return "未开票";
        case 1:
          return "申请中";
        case 2:
          return "已开票";
        case 3:
          return "已关闭";
        case 4:
          return "-";
      }
    },
    bill_type(e) {
      switch (e) {
        case 0:
          return "订单消费";
        case 1:
          return "账户充值";
        case 2:
          return "订单返款";
        case 3:
          return "余额提现";
      }
    },
  },
};
</script>

<style lang="scss" >
.table_pay {
  .paging_arr {
    margin-top: 40px;

    .hint_left {
      font-size: 12px;
      line-height: 33px;
    }
  }
  .el-table {
    background: inherit;
  }
  .el-table thead {
    color: #000000;
  }
  .el-table__body {
    border-collapse: separate;
    border-spacing: 0 4px;
    background: #eee;
  }
  .el-table th {
    background: #eee;
  }

  .el-table .hover-row {
    background: rgba(242, 200, 17, 0.5);
  }
  .el-table .el-table__row:hover {
    background: rgba(242, 200, 17, 0.7);
  }
  // 鼠标悬浮
  .el-table--enable-row-hover .el-table__body tr:hover {
    background: rgb(242, 200, 17);
    border: 1px solid #313463;
    box-shadow: 0 0 1px 1px rgb(242, 200, 17),
      inset 0 0 10px 5px rgba(242, 200, 17, 0.2);
  }
  .el-pager li.active {
    color: #000;
    cursor: default;
    background: #f2c811;
  }
}
</style>
